// Generated by Framer (d9af232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/h6Dc5xiLR";

const cycleOrder = ["MwxyZB65L"];

const serializationHash = "framer-KNdVd"

const variantClassNames = {MwxyZB65L: "framer-v-17s0b0q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, pT5xlLtn8: text ?? props.pT5xlLtn8 ?? "Positive Initial Impression "} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pT5xlLtn8, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "MwxyZB65L", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-17s0b0q", className, classNames)} data-border data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"MwxyZB65L"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-dcbf9366-25e8-40cb-a413-0c58fea4a46d, rgb(14, 18, 46))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-64302b65-26bc-4e69-ae4d-c9415e9b76b9, rgb(0, 4, 31))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><Image background={{alt: "Check Icon", fit: "fill", pixelHeight: 24, pixelWidth: 24, src: "https://framerusercontent.com/images/pzcGCSNZcsWVAGX0obOK5HtF0.svg"}} className={"framer-1t43c55"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"m1W9ejtJk"}/><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 className={"framer-styles-preset-1tzn9kx"} data-styles-preset={"h6Dc5xiLR"}>Positive Initial Impression </motion.h3></React.Fragment>} className={"framer-1yfe3ju"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"Kd7_Rarah"} style={{"--framer-paragraph-spacing": "0px"}} text={pT5xlLtn8} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KNdVd.framer-1w1hxyo, .framer-KNdVd .framer-1w1hxyo { display: block; }", ".framer-KNdVd.framer-17s0b0q { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px 24px 8px 8px; position: relative; width: min-content; }", ".framer-KNdVd .framer-1t43c55 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); overflow: hidden; position: relative; width: 24px; }", ".framer-KNdVd .framer-1yfe3ju { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KNdVd.framer-17s0b0q { gap: 0px; } .framer-KNdVd.framer-17s0b0q > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-KNdVd.framer-17s0b0q > :first-child { margin-left: 0px; } .framer-KNdVd.framer-17s0b0q > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ".framer-KNdVd[data-border=\"true\"]::after, .framer-KNdVd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 41
 * @framerIntrinsicWidth 275
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"pT5xlLtn8":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDctPXiPH7: React.ComponentType<Props> = withCSS(Component, css, "framer-KNdVd") as typeof Component;
export default FramerDctPXiPH7;

FramerDctPXiPH7.displayName = "Helper/ Pointer";

FramerDctPXiPH7.defaultProps = {height: 41, width: 275};

addPropertyControls(FramerDctPXiPH7, {pT5xlLtn8: {defaultValue: "Positive Initial Impression ", displayTextArea: true, title: "Text", type: ControlType.String}} as any)

addFonts(FramerDctPXiPH7, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})